import React from 'react';
import { string, func, bool } from 'prop-types';
import { first } from 'lodash';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '..';

import css from './CoachCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

export const CoachCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    coach,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
  } = props;
  
  const classes = classNames(rootClassName || css.root, className);
  const currentCoach = ensureUser(coach.data);
  const id = currentCoach.id.uuid;
  const { title = '' } = currentCoach.attributes;
  const slug = createSlug(id);
  const coachName = currentCoach.attributes.profile.displayName;
  const firstImage = first(coach.included?.filter(x => x.type === 'image')) || null;
  const aspectWidth = 252, aspectHeight = 186; // Matches Figma now
  const variants = firstImage?.attributes?.variants?.default ? ['default'] : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentCoach.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  return (
    <NamedLink className={classes} name="ProfilePage" params={{ id, slug }}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={coachName}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.author" values={{ authorName: coachName }} />
            </div>
          ) : null}
        </div>
      </div>
    </NamedLink>
  );
};

CoachCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

CoachCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  // listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(CoachCardComponent);
