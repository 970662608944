
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';


const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);


import React, { useEffect } from 'react';
import { ASSET_NAME, getUsers, recommendedSectionId } from './LandingPage.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { useConfiguration } from '../../context/configurationContext';

import { SectionRecommendedListings, SectionCoaches } from '../PageBuilder/SectionBuilder';
import { fetchReviews } from '../ListingPage/ListingPage.duck';

const recommendedSectionType = 'recommended';
const userSectionType = 'user';

export const LandingPageComponent = props => {
  const {
    pageAssetsData,
    listings,
    inProgress,
    error,
    currentUser,
    recommendedListingIds,
    onFetchCoaches,
    coaches,
  } = props;

  const config = useConfiguration();
  useEffect(() => {
    onFetchCoaches(recommendedListingIds, config);
  }, [recommendedListingIds]);

  // Construct custom page data
  const pageData = pageAssetsData?.[camelize(ASSET_NAME)]?.data;

  ////////////////////////////////////////////////////////
  const recommendedSectionIdx = pageData?.sections.findIndex(
    s => s.sectionId === recommendedSectionId
  );
  const recommendedSection = pageData?.sections[recommendedSectionIdx];

  const customRecommendedSection = {
    ...recommendedSection,
    sectionId: recommendedSectionId,
    sectionType: recommendedSectionType,
    listings: coaches,
  };

  const customCurrentUserSection = {
    sectionId: 'recommended-listings',
    sectionType: userSectionType,
    currentUser,
    description: {
      fieldType: 'paragraph',
      content: 'Our coaches are friendly middle and high school athletes.',
    },
    title: {
      fieldType: 'heading2',
      content: 'Introducing Some of Our Coaches',
    },
    numColumns: 4,
  };

  ////////////////////////////////////////////////////////
  const customSections = pageData
    ? pageData.sections.map((s, idx) => idx === recommendedSectionIdx ? customRecommendedSection : s)
    : null;
    
  const customPageData = pageData
    ? {
        ...pageData,
        sections: [
          ...customSections.slice(0, 1),
          customCurrentUserSection,
          ...customSections.slice(1),
        ],
      }
    : pageData;

  return (
    <PageBuilder
      pageAssetsData={customPageData}
      options={{
        sectionComponents: {
          // [recommendedSectionType]: { component: SectionRecommendedListings },
          [userSectionType]: { component: SectionCoaches },
        },
      }}
      inProgress={inProgress}
      error={error}
      fallbackPage={<FallbackPage error={error} />}
    />
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};




const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  // These are the ids from the Console section
  const { reviews } = state.ListingPage;
  const { recommendedListingIds, reviewIds, coaches } = state.LandingPage;
  const { currentUser } = state.user
  // These are the ids for the listings returned from the API –
  // they may be different than the Console ones: if for example
  // one of the listings was closed, it does not get returned from the API
  const { currentPageResultIds } = state.SearchPage;
  const listings = getListingsById(state, currentPageResultIds);
  return {
    pageAssetsData,
    listings,
    inProgress,
    error,
    recommendedListingIds,
    reviewIds,
    currentUser,
    reviews,
    coaches,
  };
};


const mapDispatchToProps = dispatch => ({
  onFetchCoaches: (params, config) => dispatch(getUsers(params, config)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LandingPageComponent);


// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
// const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);


export default LandingPage;
