import React, { useEffect, useMemo, useRef, useState } from 'react';
import { arrayOf, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { types as sdkTypes } from '../../../../util/sdkLoader';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';
import * as geocoderGoogleMaps from '../../../../components/LocationAutocompleteInput/GeocoderGoogleMaps';
import * as geocoderMapbox from '../../../../components/LocationAutocompleteInput/GeocoderMapbox';

import SectionContainer from '../SectionContainer';
import css from './SectionCoaches.module.css';

import { useConfiguration } from '../../../../context/configurationContext';
import { searchListings } from '../../../SearchPage/SearchPage.duck';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getUsers } from '../../../LandingPage/LandingPage.duck';
import { FormattedMessage } from 'react-intl';
import { titilize } from '../../../../util/uiHelpers';
import { defaultSortValue } from '../../../SearchPage/SearchPage.shared';
import DotNavigation from './DotNavigation';

const KEY_CODE_ARROW_LEFT = 37;
const KEY_CODE_ARROW_RIGHT = 39;
const { LatLng, LatLngBounds } = sdkTypes;
const currentLocationId = 'current-location';

// The number of columns (numColumns) affects styling and responsive images
const COLUMN_CONFIG = [
  { css: css.oneColumn, responsiveImageSizes: '(max-width: 767px) 100vw, 1200px' },
  { css: css.twoColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 600px' },
  { css: css.threeColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 400px' },
  { css: css.fourColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 290px' },
];
const getIndex = numColumns => numColumns - 1;
const getColumnCSS = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.css : COLUMN_CONFIG[0].css;
};
const getResponsiveImageSizes = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.responsiveImageSizes : COLUMN_CONFIG[0].responsiveImageSizes;
};

// Get correct geocoding variant: geocoderGoogleMaps or geocoderMapbox
const getGeocoderVariant = mapProvider => {
  const isGoogleMapsInUse = mapProvider === 'googleMaps';
  return isGoogleMapsInUse ? geocoderGoogleMaps : geocoderMapbox;
};

function useGeocoder(config) {
  if (!config) return;
  const geocoderRef = useRef(null);

  // Memo-ize the Geocoder variant to avoid re-computing on every render
  const Geocoder = useMemo(() => {
    const geocoderVariant = getGeocoderVariant(config.maps.mapProvider);
    return geocoderVariant.default;
  }, [config.maps.mapProvider]);

  // Lazily instantiate the Geocoder if it hasn’t been created yet
  if (!geocoderRef.current) {
    geocoderRef.current = new Geocoder();
  }

  return geocoderRef.current;
}

/**
 * Returns co-ordinates for Malrose. Which is the fallback
 */
function getMelroseLocation() {
  const ne = new LatLng(42.475925, -71.027572);
  const sw = new LatLng(42.43884, -71.082919);
  const bounds = new LatLngBounds(ne, sw);
  return { bounds };
}

// Section component that's able to show blocks in a carousel
// the number blocks visible is defined by "numColumns" prop.
const SectionCoachesComponent = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    numColumns,
    title,
    description,
    appearance,
    callToAction,
    options,
    onSearchListings,
  } = props;
  const config = useConfiguration();
  const geocoder = useGeocoder(config);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [blocks, setBlocks] = useState([]);
  const [numberOfBlocks, setNumberOfBlocks] = useState(0);
  const sliderContainerId = `${props.sectionId}-container`;
  const sliderId = `${props.sectionId}-slider`;
  const hasBlocks = numberOfBlocks > 0;

  useEffect(() => {
    const setCarouselWidth = () => {
      if (hasBlocks) {
        const windowWidth = window.innerWidth;
        const elem = window.document.getElementById(sliderContainerId);
        const scrollbarWidth = window.innerWidth - document.body.clientWidth;
        const elementWidth =
          elem.clientWidth >= windowWidth - scrollbarWidth ? windowWidth : elem.clientWidth;
        const carouselWidth = elementWidth - scrollbarWidth;

        elem.style.setProperty('--carouselWidth', `${carouselWidth}px`);
      }
    };
    setCarouselWidth();

    const getCoachesBasedOnListings = async () => {
      try {
        const currentLocationBoundsDistance = config?.maps?.search?.currentLocationBoundsDistance;
        const location = await geocoder
          .getPlaceDetails({ id: currentLocationId }, currentLocationBoundsDistance)
          .catch(() => getMelroseLocation());

        let listingsResponse;
        setLoading(true);
        while (true) {
          const params = {
            bounds: location.bounds,
            include: ['author', 'images', 'author.profileImage'],
            page: 1,
            perPage: 100,
            sort: defaultSortValue,
          };
          const resp = await onSearchListings(params, config);
          if (resp?.data?.data?.length) {
            listingsResponse = resp.data;
            break;
          } else {
            await sleep(1);
            location.bounds = getMelroseLocation().bounds;
          }
        }

        const { images, users } = listingsResponse.included.reduce(
          (obj, item) => {
            if (item.type === 'image') obj.images[item.id.uuid] = item;
            if (item.type === 'user') obj.users[item.id.uuid] = item;
            return obj;
          },
          { images: {}, users: {} }
        );

        const newBlocks = listingsResponse?.data.map(l => createUserBlock(l, images, users));
        setNumberOfBlocks(newBlocks.length);
        setBlocks(newBlocks);
      } catch (err) {
        setNumberOfBlocks(0);
        setBlocks([]);
        console.error('getPredictionAddress (error)', err);
      } finally {
        setLoading(false);
      }
    };

    getCoachesBasedOnListings();
    window.addEventListener('resize', setCarouselWidth);
    return () => window.removeEventListener('resize', setCarouselWidth);
  }, []);

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);

  const onSlideLeft = e => {
    var slider = window.document.getElementById(sliderId);
    const slideWidth = numColumns * slider?.firstChild?.clientWidth;
    slider.scrollLeft = slider.scrollLeft - slideWidth;
    // Fix for Safari
    e.target.focus();
  };

  const onSlideRight = e => {
    var slider = window.document.getElementById(sliderId);
    const slideWidth = numColumns * slider?.firstChild?.clientWidth;
    slider.scrollLeft = slider.scrollLeft + slideWidth;
    // Fix for Safari
    e.target.focus();
  };

  const onKeyDown = e => {
    if (e.keyCode === KEY_CODE_ARROW_LEFT) {
      // Prevent changing cursor position in input
      e.preventDefault();
      onSlideLeft(e);
    } else if (e.keyCode === KEY_CODE_ARROW_RIGHT) {
      // Prevent changing cursor position in input
      e.preventDefault();
      onSlideRight(e);
    }
  };

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field data={title} className={defaultClasses.title} options={fieldOptions} />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
        </header>
      ) : null}
      {hasBlocks ? (
        <div className={css.carouselContainer} id={sliderContainerId}>
          <div
            className={classNames(css.carouselArrows, {
              [css.notEnoughBlocks]: numberOfBlocks <= numColumns,
            })}
          >
            <button className={css.carouselArrowPrev} onClick={onSlideLeft} onKeyDown={onKeyDown}>
              ‹
            </button>
            <button className={css.carouselArrowNext} onClick={onSlideRight} onKeyDown={onKeyDown}>
              ›
            </button>
          </div>
          <div className={getColumnCSS(numColumns)} id={sliderId}>
            <BlockBuilder
              rootClassName={css.block}
              ctaButtonClass={defaultClasses.ctaButton}
              blocks={blocks}
              sectionId={sectionId}
              responsiveImageSizes={getResponsiveImageSizes(numColumns)}
              options={options}
              setCurrentIndex={setCurrentIndex}
            />
          </div>
          <DotNavigation
            totalItems={blocks.length}
            currentIndex={currentIndex}
          />
        </div>
      ) : (
        <div className={loading ? css.loading : css.noCoachesFound}>
          <FormattedMessage
            id={loading ? 'SearchFiltersMobile.loadingResults' : 'LandingPage.noCoachesFound'}
          />
        </div>
      )}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionCoachesComponent.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  numColumns: 1,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  options: null,
};

SectionCoachesComponent.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  numColumns: number,
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  options: propTypeOption,
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  onSearchListings: (params, config) => dispatch(searchListings(params, config)),
  onFetchCoaches: (params, config) => dispatch(getUsers(params, config)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SectionCoaches = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SectionCoachesComponent);

export default SectionCoaches;

const sleep = (seconds = 1) =>
  new Promise(resolve => {
    setTimeout(() => resolve(), seconds * 1000);
  });

const getAuthorId = listing => listing.relationships.author.data.id.uuid;
const getListingImageId = listing => listing.relationships.images.data?.[0]?.id?.uuid || null;
const getUserImageId = user => user.relationships.profileImage.data?.id?.uuid || null;

function createUserBlock(listing, images, users) {
  const authorId = getAuthorId(listing);
  const coach = users[authorId];
  const listingImageId = getListingImageId(listing);
  const coachImageId = getUserImageId(users[authorId]);
  const image = images[coachImageId || listingImageId] || {};
  const coachName = coach.attributes.profile.displayName;

  return {
    media: {
      fieldType: 'image',
      image: {
        id: image.id?.uuid,
        attributes: image.attributes,
        type: 'imageAsset',
      },
    },
    title: {
      fieldType: 'heading3',
      content: coachName,
    },
    text: {
      fieldType: 'markdown',
      content: listing.attributes.publicData.Sport.map(titilize).join(', '),
    },
    callToAction: {
      fieldType: 'none',
      content: listing.id.uuid,
    },
    alignment: 'left',
    blockType: 'defaultBlock',
    blockName: coachName,
    blockId: 'coach-user',
  };
}
